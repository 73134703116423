<template>
  <v-main id="main-content-dashboard">
    <dashboard-core-app-bar/>
    <router-view class="pt-3"/>
    <v-spacer/>
    <dashboard-core-footer/>
  </v-main>
</template>

<script>
export default {
  name: 'DashboardCoreView',

  components: {
    DashboardCoreAppBar: () => import('../core/AppBar.vue'),
    DashboardCoreFooter: () => import('../core/Footer.vue'),
  },
}
</script>
